import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import Block from "../../../Block"
import Part from "../../../Part"
import Subheading from "../../../Subheading"
import { primaryDark, primaryLight, skyBlue, white } from "../../../../../constants/colors"
import LargeTitle from "../../../LargeTitle"
import BorderedButton from "../../../../button/BorderedButton"
import ColorWrapper from "../../../ColorWrapper"
import RadiationLines from "../../../../../../svg/radiation.inline.svg"
import BurstDesktop from "../../../../../../svg/block-burst-white-desktop.svg"
import BurstTablet from "../../../../../../svg/block-burst-white-tablet.svg"
import BurstMobile from "../../../../../../svg/block-burst-white-mobile.svg"
import IllustrationRipple from "../../../../../../svg/illustration-ripple.inline.svg"
import {
  bigDesktop,
  desktop,
  largeDesktop,
  smallMobile,
  tablet,
} from "../../../../../constants/screenSizes"
import InnerBlock from "../../../InnerBlock"
import Description from "../../../Description"

const Container = styled.div``
const RadiationContainer = styled.div`
  position: absolute;
  z-index: 10;
  height: 200%;
  width: 400%;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  pointer-events: none;

  @media (max-width: ${tablet}) {
    height: 350%;
    width: 350%;
  }

  @media (max-width: ${smallMobile}) {
    height: 300%;
    width: 300%;
  }

  svg {
    height: 100%;
    width: 100%;
    fill: white;
    transform: scale(2, 1);
  }
`

const BurstWrapper = styled(ColorWrapper)`
  background-color: #176e80;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  overflow: hidden;

  @media (max-width: ${tablet}px) {
    background-size: cover;
  }
  @media (max-width: ${smallMobile}px) {
  }
`

const BurstPart = styled(Part)<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 460px;

  @media (max-width: ${largeDesktop}px) {
    width: 430px;
  }

  @media (max-width: ${bigDesktop}px) {
    width: 400px;
  }
  @media (max-width: ${desktop}px) {
    width: 330px;
  }
  @media (max-width: 735px) {
    width: 100%;
    max-width: unset;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`
const BurstInnerBlock = styled(InnerBlock)`
  @media (max-width: ${largeDesktop}px) {
    padding: 0 0 0 65px;
  }

  @media (max-width: ${bigDesktop}px) {
    padding: 0;
  }
`
const BurstText = styled.div`
  margin-bottom: 30px;
`
const BurstDescription = styled(Description)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.71px;
`

const IllustrationRippleContainer = styled.div`
  position: absolute;
  bottom: -50px;
  right: 0;
  height: 182px;
  pointer-events: none;

  @media (max-width: ${tablet}px) {
    height: 129px;
  }

  @media (max-width: ${smallMobile}px) {
    height: 91px;
  }
`

const IllustrationBackground = styled(IllustrationRipple)`
  width: 664px;
  height: 182px;

  @media (max-width: ${tablet}px) {
    width: 471px;
    height: 129px;
  }

  @media (max-width: ${smallMobile}px) {
    width: 332px;
    height: 91px;
  }
`

export interface DoubleCTAItemType {
  title: string
  description: string
  onClick: () => void
  buttonLabel: string
}

interface Props {
  items: DoubleCTAItemType[]
}

const LPDoubleCTABlock: FC<Props> = ({ items }) => {
  return (
    <BurstWrapper>
      <Block>
        <IllustrationRippleContainer>
          <IllustrationBackground />
        </IllustrationRippleContainer>
        <BurstInnerBlock>
          {items.map(({ title, description, onClick, buttonLabel }) => (
            <BurstPart key={title}>
              <BurstText>
                <LargeTitle margin="0 0 10px 0" color={white}>
                  {title}
                </LargeTitle>
                <BurstDescription color={white}>{description}</BurstDescription>
              </BurstText>
              <BorderedButton
                onClick={onClick}
                slim
                secondaryColor={primaryDark}
                icon="arrow right"
              >
                {buttonLabel}
              </BorderedButton>
            </BurstPart>
          ))}
        </BurstInnerBlock>
      </Block>
    </BurstWrapper>
  )
}

export default LPDoubleCTABlock
