import * as React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import BCorpGiftsBrandedMerchLP from "../social-supermarket/components/landing-page/new/pages/BCorpGiftsBrandedMerchLP"

const BCorpGiftsBrandedMerch = (): JSX.Element => {
  return (
    <Layout fullWidth>
      <SEO title="" description="" />
      <BCorpGiftsBrandedMerchLP />
    </Layout>
  )
}

export default BCorpGiftsBrandedMerch
